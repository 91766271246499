import React, { lazy } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router';
// import Loader from './components/Loader/Loader';

const Home = lazy(() => import(/* webpackChunkName: "Home" */ './pages/Home'));
const AboutUs = lazy(() => import(/* webpackChunkName: "About" */ './pages/About'));
const QandA = lazy(() => import(/* webpackChunkName: "QandA" */ './pages/QandA'));
const MoneyTransfer = lazy(() =>
  import(/* webpackChunkName: "MoneyTransfer" */ './pages/QandA/MoneyTransfer'),
);
const OnlineAccount = lazy(() =>
  import(/* webpackChunkName: "OnlineAccount" */ './pages/QandA/OnlineAccount'),
);
const AccountActivation = lazy(() =>
  import(/* webpackChunkName: "AccountActivation" */ './pages/QandA/AccountActivation'),
);
const BankingServices = lazy(() =>
  import(/* webpackChunkName: "BankingServices" */ './pages/QandA/BankingServices'),
);
const AggregationSuccess = lazy(() =>
  import(/* webpackChunkName: "AggregationSuccess" */ './pages/AggregationSuccess'),
);
const TransferSuccess = lazy(() =>
  import(/* webpackChunkName: "TransferSuccess" */ './pages/TransferSuccess'),
);
const NotFount = lazy(() => import(/* webpackChunkName: "404" */ './pages/404'));

export const PATHS = {
  HOME: '/',
  ABOUT_US: '/about-us',
  QANDA: '/faq',
  MONEY_TRANSFER: '/money-transfer-morocco',
  ONLINE_ACCOUNT: '/online-account',
  ACCOUNT_ACTIVATION: '/account-activation',
  BANKING_SERVICES: '/banking-services',
  AGGREGATION_SUCCESS: '/aggregation-success',
  TRANSFER_SUCCESS: '/transfer-success',
  NOT_FOUND: '*',
};

const availibaleRoutes = [
  {
    link: PATHS.HOME,
    component: Home,
    exact: true,
  },
  {
    link: PATHS.ABOUT_US,
    component: AboutUs,
    exact: false,
  },
  {
    link: PATHS.QANDA,
    component: QandA,
    exact: false,
  },
  {
    link: PATHS.MONEY_TRANSFER,
    component: MoneyTransfer,
    exact: false,
  },
  {
    link: PATHS.ONLINE_ACCOUNT,
    component: OnlineAccount,
    exact: false,
  },
  {
    link: PATHS.ACCOUNT_ACTIVATION,
    component: AccountActivation,
    exact: false,
  },
  {
    link: PATHS.BANKING_SERVICES,
    component: BankingServices,
    exact: false,
  },
];

const Routes = () => {
  const intl = useIntl();
  return (
    <Switch>
      <Redirect exact from="/" to={intl.locale} />
      {availibaleRoutes.map(route => (
        <Route
          path={`/${intl.locale}${intl.formatMessage({ id: `routes.${route.link}` })}`}
          component={route.component}
          exact={route.exact}
        />
      ))}
      <Route path={PATHS.AGGREGATION_SUCCESS} component={AggregationSuccess} />
      <Route path={PATHS.TRANSFER_SUCCESS} component={TransferSuccess} />
      <Route path="*" component={NotFount} />
    </Switch>
  );
};

export default Routes;
