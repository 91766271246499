import styled from 'styled-components';
import { borderRadius } from 'stylesheet';

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
RootContainer.displayName = 'RootContainer';

export const PageContent = styled.main`
  border-radius: ${borderRadius.large};
  flex-grow: 1;
`;
PageContent.displayName = 'PageContent';
