/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
const SPACING_UNIT = 5;
const MEASUREMENT_UNIT = 'px';

/**
 * Do not use directly the colorPalette in your components
 * Create an entry in the colorUsage below instead
 */
export const colorPalette = {
  greyLight: '#e0e0e0',
  greyDark: '#292929',
  mediumGrey: '#DADBDD',
  amberDark: '#FF8F00',
  white: '#FFFFFF',
  primary: '#003D7C',
  secondary: '#00A8FF',
  azure: '#E5E9F2',
  mabel: '#BFCEDE',
  solitude: '#F4F5F7',
  nero: '#242424',
  darkBlue: '#00264D',
};

/**
 * Use this dictionnary in your components
 * Define a new key each time you use a colour if it's for a different use
 * Ex: fill, border-color, background-color, color ...
 */
export const colorUsage = {
  darkColor: colorPalette.greyDark,
  primaryTextColor: colorPalette.primary,
  borderColor: colorPalette.azure,
  lineColor: colorPalette.mabel,
  secondaryTextColor: colorPalette.secondary,
  linkColorDisabled: colorPalette.greyLight,
  primaryButtonColor: colorPalette.white,
  loaderColorDefault: colorPalette.amberDark,
  inputBackground: colorPalette.white,
  sencondBackgroundColor: colorPalette.solitude,
  darkBackground: colorPalette.nero,
  primaryBackgroundColor: colorPalette.white,
  questionBorder: colorPalette.mediumGrey,
  darkButton: colorPalette.darkBlue,
  checkMarkColor: colorPalette.white,
};

export const fontFamily = {
  main: `'Segoe UI', 'Lato', 'Helvetica', 'Arial', sans-serif`,
  code: 'Monospace',
};

export const fontSize = {
  XXLarge: '46px',
  XLarge: '32px',
  large: '24px',
  medium: '20px',
  small: '14px',
  XSmall: '12px',
};

export const fontWeight = {
  bold: '700',
  normal: '400',
  light: '300',
};

export const lineHeight = {
  large: '36px',
  medium: '24px',
  small: '12px',
};

export const borderRadius = {
  medium: '4px',
  large: '10px',
};

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;
