export enum eventTags {
  E001 = 'E001_clic_hp_apropos_de_nous',
  E002 = 'E002_clic_hp_questions_reponses',
  E003 = 'E003_clic_hp_google_play',
  E004 = 'E004_clic_hp_app_store',
  E005 = 'E005_hp_play_video',
  E006 = 'E006_clic_popup_google_play',
  E007 = 'E007_clic_popup_app_store',
  E008 = 'E008_clic_footer_tarifs',
  E009 = 'E009_clic_footer_conditions_generales',
  E010 = 'E010_clic_footer_politique_confidentialite',
  E011 = 'E011_clic_footer_politique_cookies',
  E012 = 'E012_changement_langue',
  E013 = 'E013_clic_footer_email_contact',
  E014 = 'E014_clic_footer_tele_contact',
}
declare global {
  interface Window {
    dataLayer: any;
  }
}
type Params = {
  event: eventTags;
  langue: string;
};

export function pushEvent(event: eventTags, langue: string) {
  const dataEvent: Params = { event: event, langue: langue };
  window?.dataLayer?.push(dataEvent);
}
