import styled, { css } from 'styled-components';
import { colorUsage, fontFamily, fontSize, getSpacing } from 'stylesheet';

const Button = styled.button`
  background: ${colorUsage.primaryTextColor};
  border: 1px solid ${colorUsage.primaryTextColor};
  border-radius: ${getSpacing(1)};
  box-shadow: 0px 5px ${getSpacing(3)} rgba(0, 0, 0, 0.3);
  height: ${getSpacing(8)}
  width: ${getSpacing(30)}
  font-family: ${fontFamily.main};
  font-size: ${fontSize.small};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  text-decoration: none;
  color: ${colorUsage.inputBackground};
  margin: 10px;
  &:hover, &:focus {
    outline: none;
    background-color: ${colorUsage.secondaryTextColor};  border: 1px solid ${
  colorUsage.secondaryTextColor
};
  }

`;

export default Button;
