import React, { CSSProperties } from 'react';

interface Image {
  childrenImage: any;
  style?: CSSProperties;
  src?: any;
}

const ImageWebP = ({ childrenImage, src, style }: Image) => {
  return (
    <picture style={style}>
      <source type="image/webp" srcSet={src} />
      {childrenImage}
    </picture>
  );
};
export default ImageWebP;
