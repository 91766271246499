import styled from 'styled-components';
import backgroundImage from 'assets/images/dabaTransfertBackground.jpg';
import { fontFamily, fontSize, fontWeight, getSpacing, colorUsage, colorPalette } from 'stylesheet';

interface MainImageContainer {
  isTop?: boolean;
  isBottom?: boolean;
  maxHeight?: number;
  imageNotInMobile?: boolean;
}

export const MainImageContainer = styled.img<MainImageContainer>`
  max-height: ${props => (props?.maxHeight ? props.maxHeight : 650)}px;
  width: auto;
  object-fit: cover;
  ${props => props?.isTop && `align-self: flex-start;`}
  ${props => props?.isBottom && `align-self: flex-end;`}
  @media (max-width: ${getSpacing(250)}) {
    width: auto;
    height: auto;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  ${({ imageNotInMobile }) =>
    imageNotInMobile &&
    `@media (max-width: ${getSpacing(120)}) {
    display: none;
  }`}
`;

interface MainCardWrapperProps {
  isBlueBackground?: boolean;
  minHeight?: number;
}

export const MainCardWrapper = styled.section<MainCardWrapperProps>`
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: ${({ minHeight }) => minHeight || '0'}px;
  @media (max-width: 767px) {
    min-height: ${({ minHeight }) => (minHeight ? minHeight * 1.8 : '0')}px;
  }
  @media (min-width: ${getSpacing(125)}) {
    flex-direction: row;
  }

  ${props => props?.isBlueBackground && `background-color: ${colorUsage.primaryTextColor}`}
`;

export const MainTextWrapper = styled.div`
  align-self: center;
  margin-right: ${getSpacing(40)};
  @media (max-width: 820px) {
    margin: 0;
    flex: 100%;
  }
`;
interface TitleProps {
  isWhite?: boolean;
  isBold?: boolean;
  withTextShadow?: boolean;
  withMarginTop?: boolean;
  lineHeight?: number;
}

export const Title = styled.h1<TitleProps>`
  font-family: ${fontFamily.main};
  font-size: ${fontSize.XXLarge};
  margin-top: ${props => (props?.withMarginTop ? `${getSpacing(12)}` : `0px `)};
  ${({ isBold }) => isBold && `font-weight: ${fontWeight.bold};`}
  color: ${({ isWhite }) => (isWhite ? colorUsage.inputBackground : colorUsage.primaryTextColor)};
  ${({ lineHeight }) => lineHeight && `line-height: ${getSpacing(lineHeight)}`}
  @media (max-width: ${getSpacing(125)}) {
    text-align: center;
    font-size: ${fontSize.XLarge};
    ${({ lineHeight }) => lineHeight && `line-height: ${getSpacing(lineHeight - 1)}`}
    ${({ withTextShadow }) =>
      withTextShadow &&
      `text-shadow: 0px 0px ${getSpacing(1)} white, 0px 0px ${getSpacing(1)} white;`}
    
  }
`;

export const SectionTitle = styled.h1<TitleProps>`
  font-family: ${fontFamily.main};
  font-size: ${fontSize.XXLarge};
  line-height: ${getSpacing(13)};
  color: ${({ isWhite }) => (isWhite ? colorUsage.inputBackground : colorUsage.primaryTextColor)};
  @media (max-width: ${getSpacing(125)}) {
    text-align: center;
    font-size: ${fontSize.large};
    line-height: ${getSpacing(6)};
  }
  font-weight: ${fontWeight.bold};
  margin-top: ${props => (props?.withMarginTop ? `${getSpacing(12)}` : `0px `)};
  margin-bottom: ${getSpacing(10)};
  text-align: center;
`;

export const TitleSpan = styled.span`
  font-weight: ${fontWeight.bold};
  color: ${colorUsage.secondaryTextColor};
  font-style: italic;
  text-align: center;
`;

interface ParagraphProps {
  withMargins: boolean;
  isCentered?: boolean;
  isWhite?: boolean;
  isTextCentered?: boolean;
  maxMarginTop?: boolean;
}

export const ModalItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const Paragraph = styled.p<ParagraphProps>`
line-height: ${getSpacing(7)};
  ${props =>
    props.withMargins
      ? props.maxMarginTop
        ? `margin: ${getSpacing(12)} 0px;`
        : `margin: ${getSpacing(8)};`
      : `margin: ${getSpacing(1)} 0px;`}
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  ${props => props?.isWhite && `color: ${colorUsage.inputBackground};`}
  ${props => props?.isTextCentered && `text-align: center;`}
`;

export const ParagraphSpan = styled.span`
  color: ${colorUsage.secondaryTextColor};
`;

interface SubTitleProps {
  withMargins?: boolean;
  bigSubtitle?: boolean;
  isItalic?: boolean;
  withTextShadow?: boolean;
  textAlign?: boolean;
  showOnMobile?: boolean;
}
interface ImageWrapperProps {
  modalTest?: boolean;
  marginIconsStores?: string;
}
interface ImageWrapperHomeProps {
  left?: string;
  top?: string;
}
export const ContainerLandingPage = styled.section`
  position: relative;
  height: calc(100vh - 65px);
  width: 100%;
  background: url("${backgroundImage}") no-repeat 100% 0;
  background-size: cover;
  @media (max-width: 820px) {
    background-position:  80% 0;
  }
  @media (max-width: 420px) {
    background-position:  65% 0;
  }
`;

export const BoldText = styled.span`
  font-weight: ${fontWeight.bold};
`;

export const SubTitle = styled.p<SubTitleProps>`
  font-family: ${fontFamily.main};
  font-size: ${({ bigSubtitle }) => (bigSubtitle ? fontSize.XLarge : fontSize.large)};
  line-height: ${getSpacing(8)};
  color: ${colorUsage.darkColor};
  margin-bottom: ${getSpacing(5)};
  flex: 1;
  white-space: pre-line;
  ${({ isItalic }) => isItalic && `font-style: italic;`};
  ${({ textAlign }) => textAlign && `text-align: center;`}
  @media (max-width: ${getSpacing(125)}) {
    text-align: center;
    display:${({ showOnMobile }) => (showOnMobile ? 'block' : 'none')};
  }
  @media (max-width: ${getSpacing(230)}) {
    ${({ withTextShadow }) =>
      withTextShadow &&
      `text-shadow: 0px 0px ${getSpacing(1)} white, 0px 0px ${getSpacing(1)} white;`}
  }
`;

export const ImageWrapper = styled.div<ImageWrapperProps>`
  display: flex;
  align-items: center;
`;
export const ImageWrapperHome = styled.div<ImageWrapperHomeProps>`
  display: flex;
  align-items: center;
  margin-left: 20px;
  max-width: 340px;
  justify-content: space-between;
  @media (max-width: ${getSpacing(153)}) {
    margin-left: 0;
    flex-direction: column;
  }
`;

interface BadgeContainerProps {
  withRightMargin?: boolean;
  width?: string | number;
}
export const BadgeContainer = styled.img<BadgeContainerProps>`
  ${props => props.withRightMargin && `margin-right: ${getSpacing(4)};`}
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${getSpacing(8)};
  justify-content: flex-start;
  height: auto;
  @media (max-width: 820px) {
    flex: 50%;
  }

  @media (max-width: 500px) {
    flex: 100%;
  }
`;
export const ImgContainer = styled.div`
  margin: 0 auto;
  padding-bottom: ${getSpacing(3)};
`;

interface IconDescriptionTypes {
  isSecondary?: boolean;
}
export const IconDescription = styled.h3<IconDescriptionTypes>`
  font-family: ${fontFamily.main};
  font-size: ${fontSize.medium};
  color: ${({ isSecondary }) =>
    isSecondary ? colorUsage.secondaryTextColor : colorUsage.primaryTextColor};
  line-height: ${getSpacing(6)};
  white-space: pre-line;
  font-weight: 600;
  text-align: center;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: ${getSpacing(12)};
`;

export const Line = styled.div`
  border: 1px solid ${colorUsage.lineColor};
  width: 50vw;
  margin: auto;
`;
export const CircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CircleStep = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: ${colorPalette.solitude};
  width: ${getSpacing(15)};
  height: ${getSpacing(15)};

  @media (max-width: ${getSpacing(130)}) {
    margin: ${getSpacing(4)} 0px;
  }
`;
export const CircleList = styled.div`
  display: flex;
  border-radius: 50%;
  margin-right: ${getSpacing(2)};
  margin-top: ${getSpacing(3)};
  background-color: ${colorUsage.primaryTextColor};
  width: ${getSpacing(1.2)};
  height: ${getSpacing(1.2)};
`;

export const SmallTitleLegend = styled.p`
  flex-wrap: wrap;
  font-family: ${fontFamily.main};
  line-height: ${getSpacing(5)};
  margin-left: ${getSpacing(4)};
  @media (max-width: ${getSpacing(130)}) {
    text-align: center;
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  @media (max-width: ${getSpacing(130)}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  margin-left: ${getSpacing(13)};
  @media (max-width: ${getSpacing(130)}) {
    flex-direction: column;
  }
`;

export const LegendWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  @media (min-width: ${getSpacing(130)}) {
    width: 90%;
    transform: translateX(${getSpacing(-7)});
    padding-left: ${getSpacing(7)};
  }
`;

export const Number = styled.p`
  align-self: center;
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: ${fontSize.large};
  color: ${colorUsage.primaryTextColor};
`;
interface VaricalLineProps {
  Width?: number;
  withVerticalLine?: boolean;
  isStepColor?: boolean;
}

export const VerticalLine = styled.div<VaricalLineProps>`
  @media (min-width: ${getSpacing(130)}) {
    border: ${props =>
      props?.isStepColor
        ? `1px solid ${colorPalette.solitude}`
        : `1px solid ${colorUsage.lineColor}`};
    height: ${props => (props?.withVerticalLine ? '7vh' : '0px')};
    z-index: ${props => (props?.withVerticalLine ? '0' : '-1')};
    margin: auto;
    width: 0px;
    margin-left: ${getSpacing(7)};
    transform: translateY(${getSpacing(0)});
  }
`;

export const SmallTitle = styled.h3`
  margin-left: ${getSpacing(4)};
  margin-bottom: ${getSpacing(2)};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  font-family: ${fontFamily.main};
  @media (max-width: ${getSpacing(130)}) {
    text-align: center;
  }
`;

export const SmallTitleSpan = styled.span`
  color: ${colorUsage.secondaryTextColor};
  font-weight: 500;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${getSpacing(135)};
  align-self: center;
  margin: 0px 0px ${getSpacing(5)} 0px;
  @media (min-width: ${getSpacing(130)}) {
    margin: ${getSpacing(10)} 0px;
  }
`;

export const InstructionsImage = styled.img`
  max-height: ${getSpacing(120)};
  width: auto;
  object-fit: cover;
  align-self: center;
  @media (max-width: ${getSpacing(250)}) {
    width: auto;
    height: auto;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EllipseContainer = styled.img`
  margin: ${getSpacing(4)} ${getSpacing(1)};
  cursor: pointer;
`;

export const CardContainer = styled.div`
  background-color: ${colorUsage.primaryBackgroundColor};
  border-radius: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: ${getSpacing(8)};
  flex: 1;
  &:after {
    content: " ";
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    position:absolute;
    border-top: 15px solid ${colorUsage.primaryBackgroundColor}
    bottom: -15px;
  }

`;

export const IconContainer = styled.img`
  width: ${getSpacing(3)};
  height: ${getSpacing(3)};
`;

export const CommentContainer = styled.p`
  text-align: center;
  line-height: ${getSpacing(5)};
  margin-top: 0;
  margin-bottom: ${getSpacing(4)};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 ${getSpacing(6)};
`;

export const Thumbnail = styled.img`
  width: ${getSpacing(11)};
  height: ${getSpacing(11)};
  align-self: center;
`;

export const Name = styled.p`
  text-align: center;
  font-weight: ${fontWeight.bold};
  margin-top: ${getSpacing(5.7)};
`;

interface SectionWrapperProps {
  isSecondary?: boolean;
  isFullScreen?: boolean;
  withoutPaddingTop?: boolean;
  minHeight?: number;
}

export const SectionWrapper = styled.section<SectionWrapperProps>`
  background-color: ${props =>
    props?.isSecondary ? colorUsage.sencondBackgroundColor : colorUsage.primaryBackgroundColor};
  font-family: ${fontFamily.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${props => (props?.withoutPaddingTop ? 0 : `${getSpacing(13)}`)};
  margin: 0;
  flex-wrap: wrap;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || '0'}px;
  @media (max-width: 767px) {
    min-height: ${({ minHeight }) => (minHeight ? minHeight * 1.4 : '0')}px;
  }
`;

export const CentredContainer = styled.section`
  max-width: 1400px;
  padding: 0 3%;
  margin: 0 auto;
  @media (max-width: 1280px) {
    padding: 0 6%;
  }
`;

export const CommentsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${getSpacing(12)};
`;

export const KPIWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;
  margin: ${getSpacing(6)} ${getSpacing(0)};
  font-family: ${fontFamily.main};
`;

export const KPIContainer = styled.h2`
  font-size: ${fontSize.XXLarge};
  @media (max-width: ${getSpacing(230)}) {
    font-size: ${fontSize.XLarge};
  }
  color: ${colorUsage.secondaryTextColor};
  font-weight: ${fontWeight.bold};
  margin: ${getSpacing(3)} 0;
  text-align: center;
`;

export const KPITitle = styled.p`
  font-size: ${fontSize.medium};
  text-align: center;
`;

export const SmallLine = styled.div`
  border: 1px solid ${colorUsage.lineColor};
  width: ${getSpacing(16)};
  margin-top: ${getSpacing(4)};
`;

export const KPICardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${getSpacing(5)} ${getSpacing(0)};
  @media (max-width: 420px) {
    width: 100%;
  }
`;

export const ModalImage = styled.img`
  height: auto;
  width: ${getSpacing(90)};
  @media (max-width: ${getSpacing(180)}) {
    display: none;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${getSpacing(90)};
  @media (max-width: ${getSpacing(180)}) {
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  align-self: flex-end;
  background-color: white;
  border: 0;
  margin-top: ${getSpacing(2)};
  margin-right: ${getSpacing(2)};
`;
