import styled from 'styled-components';

import { colorUsage, getSpacing, fontSize, fontFamily } from 'stylesheet';

interface CookiesProps {
  fullscreen?: string | string[] | null;
}

export const CookiesContainer = styled.div<CookiesProps>`
  min-height: 70px
  position: sticky;
  bottom: 0px;
  background-color: ${colorUsage.primaryTextColor};
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (min-width: ${getSpacing(208)}){
    flex-direction: row; 
    align-items: center;
  }
  padding: ${getSpacing(3)};
  ${({ fullscreen }) => fullscreen === 'true' && `display: none;`}
`;

export const TextContainer = styled.div`
  font-family: ${fontFamily.main};
  font-size: ${fontSize.small};
  color: ${colorUsage.inputBackground};
  line-height: 1.3;
`;

export const CookiesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media (min-width: ${getSpacing(208)}) {
    flex-direction: row;
    width: 60%;
  }
`;

interface CookiesButtonProps {
  isPrimary?: boolean;
}

export const CookiesButton = styled.button<CookiesButtonProps>`
  cursor: pointer;
  height: ${getSpacing(8)};
  width: ${getSpacing(30)};
  color: ${colorUsage.inputBackground};
  border: 1px solid black;
  border-radius: 5px;
  font-family: ${fontFamily.main};
  font-size: ${fontSize.small};
  background-color: ${({ isPrimary }) =>
    isPrimary ? colorUsage.darkButton : colorUsage.primaryTextColor};
  margin: 0 2px;
  :hover {
    background-color: ${colorUsage.secondaryTextColor};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  padding: ${getSpacing(2)};
  flex-direction: column;
  @media (min-width: ${getSpacing(100)}) {
    flex-direction: row;
    align-self: flex-end;
  }
`;

export const CookiesLink = styled.p`
  font-family: ${fontFamily.main};
  font-size: ${fontSize.small};
  color: ${colorUsage.inputBackground};
  cursor: pointer;
  text-decoration: underline;
  :hover {
    color: ${colorUsage.secondaryTextColor};
  }
  transition: color 0.3s ease-in-out;
  padding: ${getSpacing(2)};
`;

export const LockContainer = styled.img`
  padding: ${getSpacing(2)};
  width: ${getSpacing(7)}
  height: ${getSpacing(7)}
`;
export const CookieParametersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${getSpacing(2)} ${getSpacing(2)};
`;
export const SelectAllLink = styled.p`
  font-family: ${fontFamily.main};
  font-size: ${fontSize.XSmall};
  color: ${colorUsage.secondaryTextColor};
  cursor: pointer;
  text-decoration: underline;
  :hover {
    color: ${colorUsage.secondaryTextColor};
  }
  transition: color 0.3s ease-in-out;
  padding: ${getSpacing(2)};
`;

export const CheckBoxWrapper = styled.div`
  margin: ${getSpacing(2)} 0 0;
`;
