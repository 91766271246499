import styled from 'styled-components';
import { fontSize, getSpacing, colorUsage } from 'stylesheet';

interface HeaderProps {
  fullscreen?: string | string[] | null;
}

export const HeaderContainer = styled.header<HeaderProps>`
  box-shadow: 0px 1px 0px ${colorUsage.borderColor};
  ${({ fullscreen }) => fullscreen === 'true' && `display: none;`}
`;

export const HeaderItems = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${getSpacing(13)};
  font-size: ${fontSize.small};
`;

HeaderContainer.displayName = 'HeaderContainer';

interface DivWrapperProps {
  isHorizontal?: boolean;
  disapearOnMobile?: boolean;
  withBorderBottom?: boolean;
}

export const DivWrapper = styled.div<DivWrapperProps>`
  display: flex;
  ${({ isHorizontal }) => (isHorizontal ? 'flex-direction: column' : 'flex-direction: row')};
  justify-content: center;
  align-items: center;
  ${({ disapearOnMobile }) => disapearOnMobile && '@media (max-width: 650px) {display: none;}'};
  ${({ withBorderBottom }) =>
    withBorderBottom && `box-shadow: 0px 1px 0px ${colorUsage.borderColor};`};
`;
