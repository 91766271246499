// @ts-nocheck
import React from 'react';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import styled from 'styled-components';

import franceFlag from 'assets/images/Flag_of_France.png';
import franceFlagWebp from 'assets/images/webp/Flag_of_France.webp';

import spainFlag from 'assets/images/Flag_of_Spain.png';
import spainFlagWebp from 'assets/images/webp/Flag_of_Spain.webp';

import italyFlag from 'assets/images/Flag_of_Italy.png';
import italyFlagWebp from 'assets/images/webp/Flag_of_Italy.webp';

import germanyFlag from 'assets/images/Flag_of_Germany.png';
import germanyFlagWebp from 'assets/images/webp/Flag_of_Germany.webp';

import netherlandsFlag from 'assets/images/Flag_of_Netherlands.png';
import netherlandsFlagWebp from 'assets/images/webp/Flag_of_Netherlands.webp';

import belguimFlag from 'assets/images/Flag_of_Belgium.png';
import belguimFlagWebp from 'assets/images/webp/Flag_of_Belgium.webp';

import './LanguageDropdown.css';
import { useIntl } from 'react-intl';
import ImageWebP from 'components/ImageWebP';
import { langs } from 'components/Root/Root';
import { eventTags, pushEvent } from 'services/events';

const StyledImage = styled.img`
  width: 20px;
  height: 15px;
`;
type OptionType = { value: langs; label: JSX.Element };
const options: OptionType[] = [
  {
    value: 'fr',
    label: (
      <ImageWebP
        childrenImage={
          <StyledImage
            width="20"
            height="15"
            loading="lazy"
            src={franceFlag}
            alt="dabatransfer - france"
          />
        }
        src={franceFlagWebp}
      />
    ),
  },
  {
    value: 'es',
    label: (
      <ImageWebP
        childrenImage={
          <StyledImage
            width="20"
            height="15"
            loading="lazy"
            src={spainFlag}
            alt="dabatransfer - spain"
          />
        }
        src={spainFlagWebp}
      />
    ),
  },
  {
    value: 'it',
    label: (
      <ImageWebP
        childrenImage={
          <StyledImage
            width="20"
            height="15"
            loading="lazy"
            src={italyFlag}
            alt="dabatransfer - italy"
          />
        }
        src={italyFlagWebp}
      />
    ),
  },
  {
    value: 'de',
    label: (
      <ImageWebP
        childrenImage={
          <StyledImage
            width="20"
            height="15"
            loading="lazy"
            src={germanyFlag}
            alt="dabatransfer - germany"
          />
        }
        src={germanyFlagWebp}
      />
    ),
  },
  {
    value: 'nl',
    label: (
      <ImageWebP
        childrenImage={
          <StyledImage
            width="20"
            height="15"
            loading="lazy"
            src={netherlandsFlag}
            alt="dabatransfer - netherlands"
          />
        }
        src={netherlandsFlagWebp}
      />
    ),
  },
  {
    value: 'be',
    label: (
      <ImageWebP
        childrenImage={
          <StyledImage
            width="20"
            height="15"
            loading="lazy"
            src={belguimFlag}
            alt="dabatransfer - belguim"
          />
        }
        src={belguimFlagWebp}
      />
    ),
  },
];

interface languageProps {
  value: langs;
}

type DropDownTypes = {
  setLang: (lang: langs) => void;
};
export default ({ setLang }: DropDownTypes) => {
  const intl = useIntl();
  const changeLanguage = ({ value }: languageProps) => {
    localStorage.setItem('language', value); // save in localstorage
    setLang(value); // save in Language provider
  };
  return (
    <Dropdown
      options={options}
      value={options.find(e => e.value === intl.locale)}
      controlClassName="myControlBox"
      placeholder="select a language"
      menuClassName="myMenuBox"
      onChange={lang => {
        changeLanguage(lang); // set lange to provider
        pushEvent(eventTags.E012, lang.value); // add datalayer event
      }}
    />
  );
};
