import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HomeState = Readonly<{
  firstDisplay: boolean;
  cookieChecked: boolean;
}>;

const initialState: HomeState = { firstDisplay: true, cookieChecked: false };

const homeSlice = createSlice({
  name: 'Home',
  initialState,
  reducers: {
    setFirstDisplay: (state, action: PayloadAction<boolean>) => {
      state.firstDisplay = action.payload;
    },
    setCookieChecked: (state, action: PayloadAction<boolean>) => {
      state.cookieChecked = action.payload;
    },
  },
});

export const { setFirstDisplay, setCookieChecked } = homeSlice.actions;
export default homeSlice.reducer;
