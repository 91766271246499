import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import Link from 'components/Link';
import { PATHS } from 'routes';
import { HeaderContainer, DivWrapper, HeaderItems } from './Header.style';
import LanguageDropDown from 'components/LanguageDropdown';

import styled from 'styled-components';
import BurgerButtonOpen from 'assets/images/burger_button_open.svg';
import BurgerButtonClose from 'assets/images/burger_button_close.svg';
//@ts-ignore

import { MainLogo } from 'components/common';
import { langs } from 'components/Root/Root';
import { CentredContainer } from 'pages/Home/components/MainPage.style';
import { eventTags, pushEvent } from 'services/events';

const BurgerImage = styled.img`
  cursor: pointer;
  @media (min-width: 650px) {
    display: none;
  }
`;

interface HeaderProps {
  fullscreen?: string | string[] | null;
  setLang: (lang: langs) => void;
}

const Header: React.FC<HeaderProps> = ({ fullscreen, setLang }: HeaderProps) => {
  const [open, setOpen] = useState(false);
  const closeFunc = () => setOpen(false);
  const intl = useIntl();

  return (
    <>
      <HeaderContainer fullscreen={fullscreen}>
        <CentredContainer>
          <HeaderItems>
            <RouterLink to={`/${intl.locale}${PATHS.HOME}`} onClick={closeFunc}>
              <MainLogo />
            </RouterLink>
            <DivWrapper disapearOnMobile>
              <Link
                as={RouterLink}
                to={`/${intl.locale}${intl.formatMessage({ id: `routes.${PATHS.ABOUT_US}` })}`}
                onClick={() => pushEvent(eventTags.E001, intl.locale)}
              >
                <FormattedMessage id="header.about" />
              </Link>
              <Link
                as={RouterLink}
                to={`/${intl.locale}${intl.formatMessage({ id: `routes.${PATHS.QANDA}` })}`}
                onClick={() => pushEvent(eventTags.E002, intl.locale)}
              >
                <FormattedMessage id="header.qanda" />
              </Link>
              <LanguageDropDown setLang={setLang} />
            </DivWrapper>
            <BurgerImage
              alt="burger button"
              src={open ? BurgerButtonOpen : BurgerButtonClose}
              width="24"
              height="20"
              onClick={() => setOpen(!open)}
            />
          </HeaderItems>
        </CentredContainer>
      </HeaderContainer>
      {open && (
        <DivWrapper isHorizontal withBorderBottom>
          <Link
            as={RouterLink}
            to={`/${intl.locale}${intl.formatMessage({ id: `routes.${PATHS.ABOUT_US}` })}`}
            onClick={() => {
              pushEvent(eventTags.E001, intl.locale);
              closeFunc();
            }}
          >
            <FormattedMessage id="header.about" />
          </Link>
          <Link
            as={RouterLink}
            to={`/${intl.locale}${intl.formatMessage({ id: `routes.${PATHS.QANDA}` })}`}
            onClick={() => {
              pushEvent(eventTags.E002, intl.locale);
              closeFunc();
            }}
          >
            <FormattedMessage id="header.qanda" />
          </Link>
          <LanguageDropDown setLang={setLang} />
        </DivWrapper>
      )}
    </>
  );
};

export default Header;
