import React from 'react';
import logo from 'assets/images/logo.svg';
import transparentlogo from 'assets/images/transparentLogo.svg';
import styled from 'styled-components';

type LogoProps = {
  isTransparent?: boolean;
};
const MainLogo = ({ isTransparent }: LogoProps) => {
  return (
    <Icon
      alt="Daba Transfer"
      src={isTransparent ? transparentlogo : logo}
      width="185"
      height="42"
    />
  );
};

export default MainLogo;

const Icon = styled.img`
  height: auto;
  width: 185px;
`;
