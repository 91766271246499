// @ts-nocheck

import React from 'react';
import styled from 'styled-components';
import Checkbox from 'react-custom-checkbox';

import { colorUsage, getSpacing, fontSize, fontFamily } from 'stylesheet';
import tick from 'assets/images/tick.svg';

const CheckMarkContainer = styled.img`
  width: ${getSpacing(2)};
  height: ${getSpacing(2)};
`;

const CustomCheckbox = styled(Checkbox)`
  margin: ${getSpacing(1.5)};
`;

interface CheckboxProps {
  label: string;
  isChecked: boolean;
  onChange: Function;
}

export default ({ label, isChecked, onChange }: CheckboxProps) => (
  <CustomCheckbox
    icon={<CheckMarkContainer src={tick} alt="dabatransfer - cookies" />}
    name="my-input"
    onChange={onChange}
    checked={isChecked}
    borderWidth={1}
    borderColor={'#fff'}
    labelStyle={{
      marginLeft: 5,
      userSelect: 'none',
      fontFamily: fontFamily.main,
      fontSize: fontSize.XSmall,
      color: colorUsage.inputBackground,
    }}
    size={12}
    label={label}
    borderRadius={3}
  />
);
