import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setCookieChecked } from 'redux/Home';
import {
  CookiesContainer,
  TextContainer,
  CookiesWrapper,
  ButtonsWrapper,
  CookiesButton,
  CookiesLink,
  LockContainer,
  CookieParametersWrapper,
  SelectAllLink,
  CheckBoxWrapper,
} from './Cookies.style';
import lockIcon from 'assets/images/lock_icon.svg';
import CheckBox from './Checkbox';

interface CookiesProps {
  fullscreen?: string | string[] | null;
}

export default ({ fullscreen }: CookiesProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [params, openParams] = useState(false);
  const [controlCheck, setControlCheck] = useState(false);
  const [mediaCheck, setMediaCheck] = useState(false);
  const [marketingCheck, setMarketingChek] = useState(false);
  const setAllChecked = () => {
    setControlCheck(true);
    setMediaCheck(true);
    setMarketingChek(true);
  };

  const declineAll = () => {
    dispatch(setCookieChecked(true));
  };

  const acceptAll = () => {
    dispatch(setCookieChecked(true));
    localStorage.setItem('controlCheck', 'true');
    localStorage.setItem('mediaCheck', 'true');
    localStorage.setItem('marketingCheck', 'true');
  };

  const validate = () => {
    dispatch(setCookieChecked(true));
    setAllChecked();
    if (controlCheck) localStorage.setItem('controlCheck', 'true');
    if (mediaCheck) localStorage.setItem('mediaCheck', 'true');
    if (marketingCheck) localStorage.setItem('marketingCheck', 'true');
  };

  return (
    <CookiesContainer fullscreen={fullscreen}>
      <CookiesWrapper>
        {params ? (
          <CookieParametersWrapper>
            <TextContainer>{intl.formatMessage({ id: 'cookies.text1' })}</TextContainer>
            <CheckBoxWrapper>
              <CheckBox
                label={intl.formatMessage({ id: 'cookies.audianceControl' })}
                isChecked={controlCheck}
                onChange={() => setControlCheck(!controlCheck)}
              />
              <CheckBox
                label={intl.formatMessage({ id: 'cookies.socialMedia' })}
                isChecked={mediaCheck}
                onChange={() => setMediaCheck(!mediaCheck)}
              />
              <CheckBox
                label={intl.formatMessage({ id: 'cookies.marketing' })}
                isChecked={marketingCheck}
                onChange={() => setMarketingChek(!marketingCheck)}
              />
            </CheckBoxWrapper>
            <SelectAllLink onClick={validate}>
              {intl.formatMessage({ id: 'cookies.validate' })}
            </SelectAllLink>
          </CookieParametersWrapper>
        ) : (
          <>
            <LockContainer width="35" height="35" src={lockIcon} alt="dabatransfer - cookies" />
            <TextContainer>
              {intl.formatMessage({ id: 'cookies.text1' })}
              <br />
              {intl.formatMessage({ id: 'cookies.text2' })}
            </TextContainer>
          </>
        )}
      </CookiesWrapper>
      <ButtonsWrapper>
        <CookiesLink onClick={() => openParams(!params)}>
          {intl.formatMessage({ id: 'cookies.cookieparams' })}
        </CookiesLink>
        <CookiesButton onClick={declineAll}>
          {intl.formatMessage({ id: 'cookies.declineall' })}
        </CookiesButton>
        <CookiesButton onClick={acceptAll} isPrimary>
          {intl.formatMessage({ id: 'cookies.acceptall' })}{' '}
        </CookiesButton>
      </ButtonsWrapper>
    </CookiesContainer>
  );
};
