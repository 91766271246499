import React, { ReactNode, useState, lazy, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import Header from 'components/Header';
import { flattenMessages } from 'services/i18n/intl';
import frMessages from 'translations/fr.json';
import beMessages from 'translations/be.json';
import esMessages from 'translations/es.json';
import itMessages from 'translations/it.json';
import deMessages from 'translations/de.json';
import nlMessages from 'translations/nl.json';
import Cookies from 'components/Cookies';
import queryString from 'query-string';
import useSelector from 'redux/useSelector';
import { getCookieChecked } from 'redux/Home/selectors';
import Loader from 'components/Loader/Loader';

/* Language polyfills needed for IE11, Edge, Safari 12 & 13 support
https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
*/
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
/* End of language polyfills */

import { PageContent, RootContainer } from './Root.style';
import { useHistory } from 'react-router-dom';

export type langs = 'fr' | 'es' | 'it' | 'de' | 'nl' | 'be';
const supportedlangs: langs[] = ['fr', 'es', 'it', 'de', 'nl', 'be'];
const locales = {
  fr: flattenMessages(frMessages),
  be: flattenMessages(beMessages),
  es: flattenMessages(esMessages),
  it: flattenMessages(itMessages),
  de: flattenMessages(deMessages),
  nl: flattenMessages(nlMessages),
};

// import footer lazy
const Footer = lazy(() => import(/* webpackChunkName: "Footer" */ 'components/Footer'));

interface Props {
  children: ReactNode;
}
function getDefaultLang() {
  const langFromUrl: string = window.location.pathname.split('/')[1];
  // @ts-ignore
  return supportedlangs.includes(langFromUrl) ? langFromUrl : window.location.origin.split('.')[2];
}
const Root: React.FunctionComponent<Props> = ({ children }) => {
  const getLang = (): langs => {
    const langDefaultUrl = getDefaultLang();
    let defLangFromUrl: langs = 'fr';
    switch (langDefaultUrl) {
      case 'fr':
        defLangFromUrl = 'fr';
        break;
      case 'be':
        defLangFromUrl = 'be';
        break;
      case 'it':
        defLangFromUrl = 'it';
        break;
      case 'es':
        defLangFromUrl = 'es';
        break;
      case 'de':
        defLangFromUrl = 'de';
        break;
      case 'nl':
        defLangFromUrl = 'nl';
        break;
      default:
        // @ts-ignore
        defLangFromUrl = localStorage.getItem('language') || 'fr';
        break;
    }
    return defLangFromUrl;
  };

  const history = useHistory();

  const [lang, setLang] = useState<langs>(getLang());

  const cookieChecked = useSelector(getCookieChecked);
  const mess =
    lang === 'it'
      ? locales.it
      : lang === 'es'
      ? locales.es
      : lang === 'de'
      ? locales.de
      : lang === 'nl'
      ? locales.nl
      : locales.fr;

  const changeLanguage = (nextLang: langs) => {
    // current location
    let currentPathname = history.location.pathname;

    // check if current path ends with '/' { exapmle: /fr/q-and-a/service-de-transfert-d-argent/ }
    // to avoid 404 page on change language
    if (currentPathname.endsWith('/')) {
      currentPathname = currentPathname.slice(0, -1);
    }
    // slice  / + lang (fr | it | es...)
    currentPathname = currentPathname.slice(3) || '/'; // if sliced path is empty string it means that its home page

    // find current path key
    const currentRouteKey = Object.keys(mess).find(key => mess[key] === currentPathname);

    // get translated path
    const nextPathName = currentRouteKey ? locales[nextLang]?.[currentRouteKey] : undefined;

    // push to new route is exist
    if (nextPathName) history.push(`/${nextLang}${nextPathName}`);

    // set new lang to provider
    setLang(nextLang);
  };

  const { fullscreen } = queryString.parse(window.location.search);

  return (
    <IntlProvider locale={lang} defaultLocale={lang} messages={mess}>
      <Suspense fallback={<Loader />}>
        <RootContainer>
          <Header setLang={changeLanguage} fullscreen={fullscreen} />
          <PageContent>{children}</PageContent>
          <Footer fullscreen={fullscreen} />
          {!cookieChecked && <Cookies fullscreen={fullscreen} />}
        </RootContainer>
      </Suspense>
    </IntlProvider>
  );
};

export default Root;
