import styled, { css } from 'styled-components';
import {
  borderRadius,
  colorUsage,
  fontFamily,
  fontWeight,
  getSpacing,
  lineHeight,
} from 'stylesheet';

interface ILink {
  href?: string;
  to?: string;
  disabled?: boolean;
  infooter?: boolean;
  isimportant?: boolean;
}

const Link = styled.a<ILink>`
  padding: ${getSpacing(2)} ${getSpacing(4)};

  ${({ infooter }) => infooter && `padding: ${getSpacing(1)} ${getSpacing(2)};`};

  font-weight: ${({ infooter }) => !infooter && fontWeight.bold};
  font-family: ${fontFamily.main};
  font-size: inherit;
  line-height: ${lineHeight.medium};
  text-decoration: none;

  color: ${props =>
    props.disabled
      ? colorUsage.linkColorDisabled
      : props?.infooter && props?.isimportant
      ? colorUsage.inputBackground
      : props?.infooter && !props?.isimportant
      ? colorUsage.primaryButtonColor
      : colorUsage.primaryTextColor};
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props?.infooter && !props?.isimportant ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  border: none;
  border-radius: ${borderRadius.medium};

  :hover {
    opacity: 1;
    color: ${props =>
      props.disabled
        ? colorUsage.linkColorDisabled
        : props?.infooter && !props?.isimportant
        ? colorUsage.primaryButtonColor
        : colorUsage.secondaryTextColor};
  }
`;

export default Link;
